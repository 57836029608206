<template>
  <div class="content" :style="'height:' + minHeight + 'px;overflow:scroll'">
    <div class="companyVideoArea">
    <!--  https://knot-website.oss-cn-beijing.aliyuncs.com/video/about.mp4-->
      <video
        v-show="companyVideoPlay"
        class="companyVideo"
        ref="companyVideo"
        controls
        src="https://sichuan-2.zos.ctyun.cn/knot-website/video/about.mp4"
      ></video>
      <img v-show="!companyVideoPlay" class="videoBack" src="../../assets/images/phone/about/vedio-background.png" alt="">
      <div v-show="!companyVideoPlay" class="vedioButton" @click="videoControl">
        <img src="../../assets/images/phone/home/bofang.png" alt="" />
      </div>
    </div>
    <div class="container companyContainer">
      <h2 class="companyTitle">公司介绍</h2>
      <h2 class="companySubtitle">COMPANY INTRODUCTION</h2>
      <img
        class="companyImg"
        src="@/assets/images/phone/about/gongsijieshao@3x_.png"
      />
      <div class="companyName">成都卡恩特医疗科技有限公司</div>
      <div class="companyDesc">
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;公司全称成都卡恩特医疗科技有限公司。成立于2011年，是专业研发医疗数字化产品，
        医学影像输出、医疗数字化存储的高科技、高新技术型企业。公司吸纳优秀人才及经销商，采用专业设备和自主研发的软件系统，致力于将先进的医疗
        数字化产品带入到中国医疗市场。公司推出的新型医学影像输出打印解决方案和超声输出打印解决方案对社会、经济、人性化的医疗服务发展均具有重要的意义。公司办公地址位于历史悠久，著有“蜀中江南”美称的天府之国成都市高新区天府三街吉泰路666号福年广场T2
        1204-1205。公司现拥有一批高学历、高素质的人才队伍，并与世界500强企业维持长期稳定的合作伙伴关系。秉承信守“品质第一、专业、高效”的经营理念，公司以优异的产品质量、专业的服务水平赢得了广大医院客户和行业专家们的一致认可。
      </div>
    </div>
    <div class="container companyCultureContainer">
      <h2 class="companyCultureTitle">企业文化</h2>
      <h2 class="companyCultureSubtitle">
        以人为本，关爱员工，求真务实，自强创新
      </h2>
      <el-image
        class="companyCultureInfoImg"
        fit="cover"
        :src="require('@/assets/images/phone/about/qiyewenhua@3x_.png')"
      ></el-image>
      <p class="companyCultureInfoDesc">
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;以人为本人是发展的根本目的，也是发展的根本动力，一切为了员工，一切依靠员工，二者的统一构成以人为本的完整内容。作为一个成长型的企业，卡恩特医疗树立了超越利润的社会目标，不以利润为唯一追求。<br />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;坚持人的价值高于物的价值，共同的价值高于个人的价值，客户价值和社会价值高于企业的生产价值和利润价值；坚持走创新驱动的发展道路，科技推动、管理创新，建设创新型企业，实现可持续发展；卡恩特医疗追求员工、企业、顾客的和谐共赢。
      </p>
      <div class="companyCultureCards">
        <div class="companyCultureCard">
          <el-image
            class="companyCultureCardImg"
            fit="cover"
            :src="require('@/assets/images/phone/about/qz@3x.png')"
          ></el-image>
          <p class="companyCultureCardTitle">求真</p>
          <p class="companyCultureCardDesc">
            不断总结自身特点，持续探索客观规律，实现企业科学发展
          </p>
        </div>
        <div class="companyCultureCard">
          <el-image
            class="companyCultureCardImg"
            fit="cover"
            :src="require('@/assets/images/phone/about/ws@3x.png')"
          ></el-image>
          <p class="companyCultureCardTitle">务实</p>
          <p class="companyCultureCardDesc">
            一切从实际出发去实践，有理想但不理想化
          </p>
        </div>
        <div class="companyCultureCard">
          <el-image
            class="companyCultureCardImg"
            fit="cover"
            :src="require('@/assets/images/phone/about/zq@3x.png')"
          ></el-image>
          <p class="companyCultureCardTitle">自强</p>
          <p class="companyCultureCardDesc">
            坚持自立、自信、自勉、自责，脚踏实地，百折不挠
          </p>
        </div>
        <div class="companyCultureCard">
          <el-image
            class="companyCultureCardImg"
            fit="cover"
            :src="require('@/assets/images/phone/about/cx@3x.png')"
          ></el-image>
          <p class="companyCultureCardTitle">创新</p>
          <p class="companyCultureCardDesc">
            与时俱进、勇于变革，拓展发展方向、开创发展道路
          </p>
        </div>
      </div>
    </div>
    <div class="companyVision">
      <p class="companyVisionTitle">使命愿景</p>
      <h4 class="companyVisionSubtitle">
        我们从当下开始，为将来做好准备，它将成为我们的事业设立的目标
      </h4>
      <div class="companyVisionCard">
        <img
          class="companyVisionImg"
          src="../../assets/images/phone/about/qysm@3x_.png"
          alt=""
        />
      </div>
      <div class="companyVisionCard">
        <img
          class="companyVisionImg"
          src="../../assets/images/phone/about/qyyj@3x_.png"
          alt=""
        />
      </div>
    </div>
    <Appfooter></Appfooter>
  </div>
</template>

<script>
import Appfooter from '../../components/Appfooter/index.vue'
export default {
  components: {
    Appfooter
  },
  data() {
    return {
      minHeight: 0,
      companyVideoPlay: false,
      time: false
    }
  },
  methods: {
    videoControl() {
      this.$refs.companyVideo.play()
      this.companyVideoPlay = !this.companyVideoPlay
    }
  },
  mounted(){
    this.minHeight = this.$store.state.minHeight
    var that = this
    this.$refs.companyVideo.addEventListener('ended', function () {
      // 结束
        console.log("播放结束");
        that.companyVideoPlay = false
    }, false);
  }
}
</script>

<style lang="less" scoped>
.content {
  width: 100%;
  .container {
    width: 100%;
    display: inline-block;
  }
  .companyVideoArea {
    line-height: 0px;
    width: 750px;
    height: 422px;
    position: relative;
    .videoBack{
      width: 750px;
      height: 422px;
      position: absolute;
      left: 0;
      top: 0;
      display: block;
    }
    .companyVideo {
      width: 750px;
      height: 422px;
      line-height: 0px;
    }
    .vedioButton {
      display: block;
      position: absolute;
      top: 161px;
      left: 300px;
      // background: black;
      background-size: 100% 100%;
      width: 154px;
      height: 97px;
      img {
        width: 154px;
        height: 97px;
      }
    }
  }
  .companyContainer {
    padding-bottom: 46px;
    .companyTitle {
      width: fit-content;
      margin: 0 auto;
      margin-top: 56px;
      font-size: 42px;
      font-family: PingFang SC;
      font-weight: bold;
      color: #0d1218;
    }
    .companySubtitle {
      width: fit-content;
      margin: 0 auto;
      margin-top: 28px;
      font-size: 17px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #6d7579;
      line-height: 31px;
      letter-spacing: 1px;
    }
    .companyImg {
      display: block;
      width: 690px;
      height: 386px;
      margin: 0 auto;
      margin-top: 39px;
    }
    .companyName {
      margin: 0;
      padding: 0 36px;
      margin-top: 67px;
      font-size: 34px;
      font-family: PingFang SC;
      font-weight: 800;
      color: #0d1218;
    }
    .companyDesc {
      margin: 0;
      margin-top: 37px;
      padding: 0 36px;
      letter-spacing: 2px;
      font-size: 28px;
      font-family: PingFang SC;
      color: #2d292e;
      line-height: 48px;
    }
  }
  .companyStrategy {
    overflow: hidden;
    width: 1200px;
    height: fit-content;
    background: #f6f5f6;
    margin: 0 auto;
    .companyStrategyTitle {
      font-size: 52px;
      font-family: PingFang SC;
      font-weight: bold;
      color: #29262a;
      text-align: center;
      margin-top: 88px;
    }
    .companyStrategyDesc {
      font-size: 22px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #606062;
      text-align: center;
      margin-top: 37px;
    }
    .companyStrategyCards {
      width: 1200px;
      height: 1285px;
      margin: 0 auto;
      margin-top: 46px;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      flex-wrap: wrap;
      .companyStrategyCard {
        border: 2px solid #fff;
        &:nth-child(2n + 1) {
          margin-left: 0px;
        }
        margin-top: 44px;
        margin-left: 25px;
        width: 583.5px;
        height: 621px;
        background: #ffffff;
        box-shadow: 0px 7px 24px 0px rgba(12, 41, 72, 0.16);
        .companyStrategyCardImg {
          width: 530px;
          height: 300px;
          margin: 0 auto;
          margin: 28px;
        }
        .companyStrategyCardTitle {
          font-size: 26px;
          font-family: Microsoft YaHei;
          font-weight: bold;
          color: #3c3c3c;
          width: 530px;
          margin: 0 auto;
          margin-top: 33px;
        }
        .companyStrategyCardDesc {
          font-size: 14px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #999999;
          line-height: 35px;
          width: 530px;
          margin: 0 auto;
          margin-top: 20px;
        }
      }
      .companyStrategyCard:hover {
        border: 2px solid #559ef3;
      }
    }
  }
  .companyCultureContainer {
    padding-bottom: 74px;
    height: fit-content;
    display: block;
    overflow: hidden;
    background: #f6f5f6;
    .companyCultureTitle {
      width: fit-content;
      margin: 0 auto;
      margin-top: 58px;
      font-size: 42px;
      font-family: PingFang SC;
      font-weight: bold;
      color: #0d1218;
    }
    .companyCultureSubtitle {
      margin: 0 auto;
      width: fit-content;
      margin-top: 37px;
      font-size: 30px;
      font-family: PingFang SC;
      font-weight: 400;
      color: #646a73;
    }
    .companyCultureInfoImg {
      display: block;
      width: 690px;
      height: 386px;
      margin: 0 auto;
      margin-top: 58px;
    }
    .companyCultureInfoDesc {
      width: 690px;
      margin: 0 auto;
      margin-top: 50px;
      font-size: 28px;
      font-family: PingFang SC;
      color: #2d292e;
      line-height: 50px;
    }
    .companyCultureCards {
      width: 678px;
      height: fit-content;
      margin: 0 auto;
      margin-top: 21px;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      .companyCultureCard {
        &:nth-child(2n + 1) {
          margin-left: 0px;
        }
        margin-left: 32px;
        width: 310px;
        height: 452px;
        border-radius: 10px;
        background: white;
        border: 3px solid #ece9e9;
        margin-top: 36px;
        .companyCultureCardImg {
          display: block;
          margin: 0 auto;
          width: 100px;
          height: 100px;
          margin: 0 auto;
          margin-top: 71px;
        }
        .companyCultureCardTitle {
          width: fit-content;
          letter-spacing: 6px;
          margin: 0 auto;
          margin-top: 36px;
          font-size: 32px;
          font-family: PingFang SC;
          font-weight: bold;
          color: #2c2c2c;
        }
        .companyCultureCardDesc {
          text-align: center;
          letter-spacing: 1px;
          padding: 0 22px;
          margin-top: 32px;
          font-size: 24px;
          font-family: PingFang SC;
          font-weight: 500;
          color: #999999;
          line-height: 36px;
        }
      }
    }
  }
  .companyVision {
    padding-bottom: 74px;
    height: fit-content;
    margin: 0 auto;
    .companyVisionTitle {
      text-align: center;
      margin: 0 auto;
      margin-top: 67px;
      font-size: 42px;
      font-family: PingFang SC;
      font-weight: bold;
      color: #0d1218;
    }
    .companyVisionSubtitle {
      margin-top: 36px;
      text-align: center;
      font-size: 30px;
      font-family: PingFang SC;
      font-weight: 400;
      color: #6d7579;
      line-height: 47px;
      padding: 0 60px;
    }
    .companyVisionCard {
      margin: 0 auto;
      overflow: hidden;
      margin-top: 53px;
      width: 690px;
      display: block;
      height: 386px;
      .companyVisionImg {
        width: 690px;
        height: 386px;
      }
    }
  }
}
</style>
