<template>
  <div>
    <div class="appfooter" :style="'height:' + minHeight + 'px'">
      <div class="from">
        <div class="fromheight">
          <h1>联系我们</h1>
          <p>请写下联系方式，以便我们能及时联系你</p>
          <div class="input">
            <div>
              <el-input
                v-model="companyName"
                @blur="companyNameRules()"
                placeholder="企业名/姓名"
              ></el-input>
              <p class="tips">{{ tipscompanyName }}</p>
            </div>
            <div>
              <el-input
                v-model="phoneNumber"
                maxlength="11"
                oninput="value=value.replace(/[^\d]/g,'')"
                @blur="adminPhoneRules()"
                placeholder="手机号"
              ></el-input>
              <p class="tips">{{ tipsMsg }}</p>
            </div>
          </div>
          <div class="btn" @click="submit">提交</div>
        </div>
      </div>
      <Footer />
    </div>
  </div>
</template>

<script>
import { createMessage } from '@/api/journalism'
import Footer from './footerbottom.vue'
export default {
  components: { Footer },
  data() {
    return {
      minHeight: 0,
      tipsMsg: '',
      tipscompanyName: '',
      phoneNumber: '',
      companyName: '',
      fromHeight: 0
    }
  },
  computed: {},
  mounted() {
    this.$nextTick(() => {
      this.minHeight = this.$store.state.minHeight
      this.fromHeight = this.minHeight - 300
    })
    console.log(this.$store.state)
  },
  methods: {
    adminPhoneRules(v) {
      const reg = /^[1][3,4,5,7,8,9][0-9]{9}$/
      if (!this.phoneNumber) {
        this.tipsMsg = '请输入电话号码'
      } else if (this.phoneNumber && !reg.test(this.phoneNumber)) {
        this.tipsMsg = '请输入正确的电话号码'
      } else {
        this.tipsMsg = ''
      }
    },
    companyNameRules(v) {
      if (!this.companyName) {
        this.tipscompanyName = '请输入企业/姓名'
      } else {
        this.tipscompanyName = ''
      }
    },
    async submit() {
      if (!this.companyName) {
        this.tipscompanyName = '请输入企业/姓名'
      }
      const reg = /^[1][3,4,5,7,8,9][0-9]{9}$/
      if (!this.phoneNumber) {
        this.tipsMsg = '请输入电话号码'
        return
      } else if (this.phoneNumber && !reg.test(this.phoneNumber)) {
        this.tipsMsg = '请输入正确的电话号码'
        return
      } else {
        this.tipsMsg = ''
      }
      this.adminPhoneRules()
      const { code, msg } = await createMessage({
        companyName: this.companyName,
        phoneNumber: this.phoneNumber
      })
      if (code === '0') {
        this.$message.success(msg)
        this.companyName = ''
        this.phoneNumber = ''
      } else {
        this.$message.error(msg)
      }
    }
  }
}
</script>

<style lang="less" scoped>
.appfooter {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-sizing: border-box;
  background: url('../../assets/images/phone/home/sy_bj7@2x_.png') no-repeat;
  width: 100%;
  background-size: 100% 100%;
  .from {
    height: 70%;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    .fromheight {
      // height: 300px;
      align-self: center;
      .input {
        // display: flex;
        margin-top: 6%;
        .el-input {
          width: 544px;
          margin-top: 40px;
          height: 48px;
          border-radius: 8px;
          /deep/.el-input__inner {
            border: 1px solid #20adff;
            background-color: transparent;
            color: #fff;
          }
        }
      }
      .btn {
        text-align: center;
        background: url('../../assets/images/phone/home/anniu.png') no-repeat;
        background-size: 100%;
        width: 550px;
        height: 70px;
        line-height: 70px;
        cursor: pointer;
        border-radius: 8px;
        color: #20adff;
        margin: auto;
        margin-top: 73px;
      }
      .tips {
        padding-top: 20px;
        height: 20px;
        color: #ccc;
        font-size: 24px;
      }
      h1 {
        font-size: 54px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: #20adff;
      }
      p {
        font-size: 25px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #1883c1;
      }
    }
  }
  .bottom {
    height: 23%;
    padding-top: 20px;
    background: #040c1b;
    flex: end;
    color: #fff;
    -moz-user-select: none; /* Firefox私有属性 */
    -webkit-user-select: none; /* WebKit内核私有属性 */
    -ms-user-select: none; /* IE私有属性(IE10及以后) */
    -khtml-user-select: none; /* KHTML内核私有属性 */
    -o-user-select: none; /* Opera私有属性 */
    user-select: none; /* CSS3属性 */

    p {
      font-size: 12px;
      font-family: Adobe Heiti Std;
      font-weight: normal;
      line-height: 40px;
      padding-left: 80px;
    }
    .btom {
      flex: 1;
      text-align: center;
      border-top: 2px solid #19253a;
      padding-top: 10px;
      display: flex;
      justify-content: center;
      align-items: center;
      p {
        padding: 0 !important;
        color: rgba(255, 255, 255, 0.42);
        margin: 0;
      }
    }
  }
}
</style>
